import request from '@/utils/axios-request'
// 查询 controller-临时访问注册信息
export const apiTemporaryRegisterInfo = (data: any) => {
  return request({
    url: '/search/temporary-register-info',
    method: 'post',
    data
  })
}
// 下载报告列表
export const apiReportRecordList = (data: any) => {
  return request({
    url: '/report/report-record-list',
    method: 'post',
    data
  })
}
// 报告下载
export const apiDownload = (data: any) => {
  return request({
    url: '/report/download',
    method: 'post',
    data: { ...data, _isNoShowErrorMessage: true }
  })
}
// company信息
export const apiCompanyInfo = (data: any) => {
  return request({
    url: '/search/company-info',
    method: 'post',
    data
  })
}
// // 普通用户访问注册信息
// export const apiRegisterInfo = (params: any) => {
//   return request({
//     url: '/search/register-info',
//     method: 'get',
//     params
//   })
// }
// // deep信息
// export const apiDeepInfo = (data: any) => {
//   return request({
//     url: '/search/deep-info',
//     method: 'post',
//     data
//   })
// }
// // pro信息
// export const apiProInfo = (data: any) => {
//   return request({
//     url: '/search/pro-info',
//     method: 'post',
//     data
//   })
// }
// 查询公司info信息
export const apiSearchCompanyInfo = (data: any) => {
  return request({
    url: '/search/search-company-info',
    method: 'post',
    data
  })
}
// // 查询公司deep信息
// export const apiSearchCompanyDeep = (data: any) => {
//   return request({
//     url: '/search/search-company-deep',
//     method: 'post',
//     data
//   })
// }
// // 查询公司pro信息
// export const apiSearchCompanyPro = (data: any) => {
//   return request({
//     url: '/search/search-company-pro',
//     method: 'post',
//     data
//   })
// }
// 获取收藏和订阅
export const apiGetMonitorSave = (params?: any) => {
  return request({
    url: '/search/get-monitor-save',
    method: 'get',
    params
  })
}
// 订阅页面初始化
export const apiInit = () => {
  return request({
    url: '/subscribe/init',
    method: 'get'
  })
}
// 新增用户卡信息
export const apiAddUserCard = (data: any) => {
  return request({
    url: '/subscribe/add-user-card',
    method: 'post',
    data
  })
}
// 立即订阅
export const apiBuyNow = (data: any) => {
  return request({
    url: '/subscribe/buy-now',
    method: 'post',
    data
  })
}
// 取消订阅
export const apiCancelSubscribe = (params: any) => {
  return request({
    url: '/subscribe/cancel-subscribe',
    method: 'get',
    params
  })
}
// 监控
export const apiMonitorAdd = (data: any) => {
  return request({
    url: '/monitor/add',
    method: 'post',
    data
  })
}
// 删除监控
export const apiMonitorDel = (data: any) => {
  return request({
    url: '/monitor/del',
    method: 'post',
    data
  })
}
// 收藏
export const apiSaveAdd = (data: any) => {
  return request({
    url: '/save/add',
    method: 'post',
    data
  })
}
// 删除收藏
export const apiSaveDel = (data: any) => {
  return request({
    url: '/save/del',
    method: 'post',
    data
  })
}
// 分享奖励
export const apiShareReward = () => {
  return request({
    url: '/user/share_reward',
    method: 'get'
  })
}
// 显示LinkedIn
export const apiShowLinkedin = () => {
  return request({
    url: '/user/show_linkedin',
    method: 'get'
  })
}
