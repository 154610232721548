import { createApp } from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import App from './App.vue'
import router from './router'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import 'element-plus/dist/index.css'
import '@/styles/index.scss' // global css、

import 'animate.css'
import 'animate.css/animate.compat.css'

import WOW from 'wow.js'

import VueNumber from 'vue-number-animation'

import { directiveFunc } from './utils/direactive'

import { createMetaManager } from 'vue-meta'

const app = createApp(App)

// 动画效果
app.config.globalProperties.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 0, // default
  mobile: true, // default
  live: false,

  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.
  /* eslint-disable @typescript-eslint/no-explicit-any */
  callback: function (box: any) {
    console.log('WOW: animating <' + box.tagName.toLowerCase() + '>')
  }, // default
  scrollContainer: null,
  resetAnimation: true
})

// directive
directiveFunc(app)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app
  .use(createMetaManager(false, {
    meta: { tag: 'meta', nameless: true }
  }))
  .use(router)
  .use(VueNumber)
  .use(VueReCaptcha, {
    siteKey: '6Lf6oPQqAAAAAN2NYR080i7DstWFjYOTW7wMu2zl',
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
  })
  .mount('#app')
